import { fetchOneEntry, isEditing, type BuilderContent } from '@builder.io/sdk-svelte';
import type { Page } from '@sveltejs/kit';
import { error } from '@sveltejs/kit';

export const _BUILDER_PUBLIC_API_KEY = '4af83327ca754af5a742ab8193d05128';

export async function load(page: Page): Promise<BuilderPageData> {
  const content = await fetchOneEntry({
    model: 'page',
    apiKey: _BUILDER_PUBLIC_API_KEY,
    userAttributes: {
      urlPath: page.url.pathname
    }
  });
  const canShowContent = Boolean(content || isEditing());

  if (!content) {
    throw error(404, {
      message: 'Not found'
    });
  }

  return {
    content,
    canShowContent
  };
}

export interface BuilderPageData {
  content: BuilderContent | null;
  canShowContent: boolean;
}
